'use strict';

import $ from 'jquery';
import 'bootstrap';
import 'owl.carousel';
import 'cookieconsent';

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab);
dom.watch();


import './site.js';

import '../scss/site.scss';