window.cookieconsent.initialise({
	container: document.getElementById("content"),
	palette:{
		popup: {background: "#000"},
		button: {
			background: "#27AAE1",
			text: "#FFFFFF",
			border: "#27AAE1"
		},
	},
	position: "bottom-left",
	showLink: false
});


$(document).ready(function() {
	$('#owl_carousel').show();
	$('#owl_carousel').owlCarousel({
		autoplay:true,
		autoplaySpeed:1500,
		autoplayTimeout:7000,
		dots:false,
		items:1,
		loop:true,
		margin:0,
		nav:true,
		navSpeed: 1500,
		singleItem:true,
    	stagePadding: 0,
    	navText : ["<img src='/public/assets/images/icons/arrow-left.png' />","<img src='/public/assets/images/icons/arrow-right.png' />"],
	});

	$('#news_carousel').show();
	$('#news_carousel').owlCarousel({
		autoplay:true,
		autoplaySpeed:1500,
		autoplayTimeout:8000,
		dots:false,
		loop:true,
		margin: 20,
		nav: true,
		navSpeed: 1500,
    	navText : ["<img src='/public/assets/images/icons/arrow-left.png' />","<img src='/public/assets/images/icons/arrow-right.png' />"],
    	responsive : {
		    // breakpoint from 0 up
		    0 : {
		        items:1,
				stagePadding: 10,
		    },
		    // breakpoint from 480 up
		    480 : {
		        items:1,
				stagePadding: 10,
		    },
		    // breakpoint from 768 up
		    768 : {
		        items:2,
				stagePadding: 10,
		    },
		    // breakpoint from 992 up
		    992 : {
		        items:3,
				margin: 20,
		    },
		    // breakpoint from 1025 up
		    1025 : {
		        items:4,
		    }
		}
	});


	$('#mobile_nav .dropdown-submenu .caret').on("click", function(e){
		$(this).next('ul').toggle();
		e.stopPropagation();
		e.preventDefault();
	});


    $('#mobile_nav li#mobile_search_link').click(function() {
		$( "#mobile_search" ).toggleClass( "d-none" );
	});
});